<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<OnSiteMessageStripForm
		v-else
		is-edit-mode
		:is-loading="isLoading"
		:is-updating="isUpdating"
		:data="data"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import OnSiteMessageStripForm from '@/components/OnSiteMessageStripForm.vue';

import { ROUTE_NAME } from '../enums/route';
import { ON_SITE_MESSAGE_COMPONENTS } from '../enums/onSiteMessages';
import { scrollToTop } from '../assets/js/helpers';

export default {
	name: 'OnSiteMessageStripEdit',

	components: {
		OnSiteMessageStripForm,
	},

	data() {
		return {
			id: Number(this.$route.params.id) || null,
		};
	},

	computed: {
		...mapState('onSiteMessages', {
			edit: 'edit',
		}),

		...mapGetters({
			getCampaignDetail: 'onSiteMessages/getCampaignDetail',
		}),

		isLoading() {
			return this.edit.isLoading;
		},

		isUpdating() {
			return this.edit.isUpdating;
		},

		data() {
			return this.getCampaignDetail;
		},
	},

	async created() {
		await this.getStripCampaign(this.id);

		this.setPageTitle({
			title: this.data.name,
			isShow: true,
		});

		this.addBreadcrumb({
			title: ON_SITE_MESSAGE_COMPONENTS.ON_SITE_MESSAGE_STRIP.title,
			route: {
				name: ROUTE_NAME.ON_SITE_MESSAGE_STRIP_LIST,
			},
		});
	},

	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			addBreadcrumb: 'breadcrumb/addBreadcrumb',
			setPageTitle: 'pageTitle/setPageTitle',

			updateStripCampaign: 'onSiteMessages/updateStripCampaign',
			getStripCampaign: 'onSiteMessages/getStripCampaign',
		}),

		async handleSubmit(updatedData) {
			try {
				await this.updateStripCampaign({ id: this.id, params: updatedData });
			} catch {
				scrollToTop();
			}
		},
	},
};
</script>
