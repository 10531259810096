<template>
	<form class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<OnSiteMessageGeneral
			:id="id"
			ref="general"
			name-label="Campaign name*"
			:name.sync="name"
			:status.sync="status"
			:priority.sync="priority"
			:has-session-base="false"
			has-priority
		/>

		<OnSiteMessageDate
			ref="date"
			:status="status"
			:start-date.sync="startDate"
			:start-time.sync="startTime"
			:end-date.sync="endDate"
			:end-time.sync="endTime"
		/>

		<!-- TODO: update preview image -->
		<OnSiteMessageContentWrapper
			ref="content"
			desktop-img="/assets/images/small-banner-desktop.svg"
			mobile-img="/assets/images/small-banner-mobile.svg"
			:campaign-link.sync="campaignLink"
		>
			<template #title>
				Strip bar image
			</template>

			<template #detail>
				Strip bar campaign will be displayed on the middle of screen as shown. Using the same size for desktop and mobile.
			</template>

			<BaseMediaSelectorBox
				ref="desktopImage"
				v-model="desktopImage"
				title="Desktop Image*"
				no-image-text="No desktop Image"
				show-helper
				width="1731"
				height="80"
				class="mt-4"
				:required="isRequiredImage"
			/>

			<BaseMediaSelectorBox
				ref="mobileImage"
				v-model="mobileImage"
				title="Mobile Image*"
				no-image-text="No mobile Image"
				show-helper
				width="869"
				height="160"
				class="mt-4"
				:required="isRequiredImage"
			/>

			<CRow>
				<CCol>
					<div class="mb-2">
						<Label>Desktop background color*</Label>
						<BaseColorPicker v-model="desktopBackgroundColor" />
					</div>
				</CCol>
			</CRow>

			<CRow>
				<CCol>
					<div class="mb-2">
						<Label>Mobile background color*</Label>
						<BaseColorPicker v-model="mobileBackgroundColor" />
					</div>
				</CCol>
			</CRow>
		</OnSiteMessageContentWrapper>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading || isUpdating"
			:is-edit="isEditMode"
			:remove-text="isEditMode ? 'Remove campaign' : null"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: ROUTE_NAME.ON_SITE_MESSAGE_STRIP_LIST})"
			@onRemove="isEditMode ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteStripCampaign.bind(null, id)"
			title="Remove this campaign?"
			description="By removing this, this campaign info will be disappeared from the list."
			@onSuccess="$router.push({ name: ROUTE_NAME.ON_SITE_MESSAGE_STRIP_LIST})"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';

import OnSiteMessageGeneral from '@/components/OnSiteMessageGeneral.vue';
import OnSiteMessageDate from '@/components/OnSiteMessageDate.vue';
import OnSiteMessageContentWrapper from '@/components/OnSiteMessageContentWrapper.vue';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';

import { convertDateTimeToUTC } from '../assets/js/helpers';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'OnSiteMessageStripForm',

	components: {
		OnSiteMessageGeneral,
		OnSiteMessageDate,
		OnSiteMessageContentWrapper,
		BaseMediaSelectorBox,
	},

	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isUpdating: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			ROUTE_NAME,

			id: null,
			name: '',
			priority: 0,
			status: true,

			startDate: null,
			startTime: '00:00',
			endDate: null,
			endTime: '23:59',

			desktopImage: null,
			mobileImage: null,
			desktopBackgroundColor: '#FF0000',
			mobileBackgroundColor: '#FF0000',
			campaignLink: null,
		};
	},

	computed: {
		isRequiredImage() {
			return !this.desktopImage && !this.mobileImage;
		},
	},

	watch: {
		data: {
			immediate: true,
			handler(value) {
				if (this.isEditMode && !this.isUpdating) {
					this.id = value.id;
					this.name = value.name;
					this.duration = value.duration;
					this.sessionBased = value.sessionBased;
					this.status = value.status;

					this.startDate = value.startDate;
					this.startTime = value.startTime;
					this.endDate = value.endDate;
					this.endTime = value.endTime;

					this.desktopImage = value.desktopImage;
					this.mobileImage = value.mobileImage;
					this.desktopBackgroundColor = value.desktopBackgroundColor;
					this.mobileBackgroundColor = value.mobileBackgroundColor;
					this.campaignLink = value.link;
				}
			},
		},
	},

	methods: {
		...mapActions({
			deleteStripCampaign: 'onSiteMessages/deleteStripCampaign',
		}),

		handleChangeEndDate(value) {
			this.endDate = value;
		},

		async handleSubmit() {
			const generalRef = this.$refs.general.$v;
			const dateRef = this.$refs.date.$v;
			const contentRef = this.$refs.content.$v;
			const desktopImage = this.$refs.desktopImage.$v;
			const mobileImage = this.$refs.mobileImage.$v;

			generalRef.$touch();
			dateRef.$touch();
			contentRef.$touch();
			desktopImage.$touch();
			mobileImage.$touch();

			if (
				!generalRef.$invalid &&
				!dateRef.$invalid &&
				!contentRef.$invalid &&
				!desktopImage.$invalid &&
				!mobileImage.$invalid
			) {
				let data = {
					name: this.name,
					is_active: this.status,
					priority: this.priority,
					start_at: convertDateTimeToUTC(this.startDate, this.startTime),
					end_at: convertDateTimeToUTC(this.endDate, this.endTime),
					desktop_background_color: this.desktopBackgroundColor,
					mobile_background_color: this.mobileBackgroundColor,
					link: this.campaignLink,
				};

				if (this.desktopImage?.id) {
					data = {
						...data,
						desktop_image_file_id: this.desktopImage?.id ?? null,
					};
				}

				if (this.mobileImage?.id) {
					data = {
						...data,
						mobile_image_file_id: this.mobileImage?.id ?? null,
					};
				}

				this.$emit('onSubmit', data);
			}
		},

		handleRemove() {
			this.$emit('onRemove');
		},
	},
};
</script>

<style lang="scss" scoped>
	.remove-banners {
		font-size: rem(14);
		color: $color-orange;

		align-items: center;

		cursor: pointer;
	}
</style>
